export interface Notification {
  show: boolean
  type: 'info' | 'error' | 'success' | 'warning'
  text: string
  time: Date
  id: number
}

export const useNotificationStore = defineStore('notification', () => {
  const notifications = ref<Notification[]>([
    // {
    //   id: 1,
    //   show: false,
    //   text: 'Anruf-Erinnerung angelegt.',
    //   time: new Date('2024-02-13T14:02:50.356Z'),
    //   type: 'success',
    // },
    // {
    //   id: 2,
    //   show: false,
    //   text: 'Angebot wurde erstellt.',
    //   time: new Date('2024-02-13T14:02:52.824Z'),
    //   type: 'info',
    // },
    // {
    //   id: 3,
    //   show: false,
    //   text: 'Reklamation wurde angelegt.',
    //   time: new Date('2024-02-13T14:05:54.701Z'),
    //   type: 'info',
    // },
  ])
  const notificationCount = computed(() => notifications.value.length)

  function addNotification(text: string, type: Notification['type'] = 'info') {
    notifications.value.push({
      id: notificationCount.value + 1,
      show: true,
      text,
      time: new Date(),
      type,
    })
  }
  function delNotification(id: number) {
    const index = notifications.value.findIndex(m => m.id === id)
    if (index !== -1)
      notifications.value.splice(index, 1)
  }
  function $reset() {
    notifications.value = []
  }

  return {
    $reset,
    addNotification,
    delNotification,
    notificationCount,
    notifications,
  }
})

export const Notify = {
  error: (val: any) => {
    let text = ''
    if (typeof val === 'string')
      text = val
    else if (val instanceof Error)
      text = val.message
    else
      text = JSON.stringify(val)

    useNotificationStore().addNotification(text, 'error')
  },
  info: (text: string) => useNotificationStore().addNotification(text, 'info'),
  success: (text: string) =>
    useNotificationStore().addNotification(text, 'success'),
  warning: (text: string) =>
    useNotificationStore().addNotification(text, 'warning'),
}

// make sure to pass the right store definition, `useAuth` in this case.
if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useNotificationStore, import.meta.hot))
